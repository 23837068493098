<template>
  <!-- <div class="offer-wrapper">
    <div class="offer-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>What we offer ?</h3>
          </div>
          <div class="col-lg-3 col-md-6" v-for="(offer, index) in offers">
            <div class="offer-box">
              <div class="img">
                <img class="img-fluid" :src="`/assets/${offer.img}.svg`" alt="">

              </div>
              <div class="title">{{ offer.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="w-100 my-5" style="background-color: #eff6fa !important">
    <div class="container pb-5">
      <SectionHeader
        titleLeft="Our Core "
        titleRight="Values"
        description="Our core values are the guiding principles that underpin our work and define our culture. Integrity is at the heart of everything we do; we believe in honesty, transparency, and ethical behavior."
      />
      <div>
        <div class="row gx-3">
          <div
            class="col-sm-3 mb-3"
            v-for="(item, index) in coreValues"
            :key="index"
          >
            <div class="d-flex p-4 gap-2 align-items-center core-card">
              <div>
                <img
                  :src="item.icon"
                  alt=""
                  class="core-card-icon object-fit-contain image-process"
                />
              </div>
              <div></div>
              <div>
                <h6 class="fs-4">{{ item.title }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeader from "../../components/SectionHeader.vue";
export default {
  name: "whatOffer",
  components: {
    SectionHeader,
  },
  data() {
    return {
      offers: [
        { title: "Boost yourself with unlimited coffee.", img: "coffee" },
        { title: "Enjoy your lunch with everyone.", img: "lunch" },
        { title: "Try some evening snack with everyone!", img: "nachos" },
        { title: "Refresh your mind by playing game!", img: "game" },
        { title: "Spend weekend with your family.", img: "holiday" },
        { title: "You will never get bored in here.", img: "best" },
        { title: "Keep yourself fit by exercising daily.", img: "enc" },
        { title: "Enjoy your annual retreat with us!", img: "family" },
      ],
    };
  },
  computed: {
    coreValues() {
      console.log("Core values:", this.$store.state.coreValues);
      return this.$store.state.coreValues;
    },
  },
  created() {
    this.$store.dispatch("fetchCoreValues");
  },
};
</script>

<style scoped>
.core-card {
  background: #fdfdfd !important;

  border-radius: 12px;
  cursor: pointer;
  min-height: 130px;
}
.core-card-icon {
  width: 30px;
  height: 30px;
}
</style>
